<template>
	<div id="directa-container">
		<div id="payment-type-container">
			<span>{{ languageStrings.paymentType }}</span>
			<select
				title="Select Provider"
				class="curved-border encore-color bold-text extra-padding-text-fields"
				v-model="paymentType"
				id="paymentType"
			>
				<option v-for="(item, index) in webPayOptions?.directa?.paymentMethods" :key="index" :value="item">
					{{ item?.name }}
				</option>
			</select>
			<span>{{ languageStrings.documentType}}</span>
			<select
				class="curved-border encore-color bold-text extra-padding-text-fields"
				id="doc-type-select"
				v-model="docType"
			>
				<option value="PASS">{{ languageStrings.passport }}</option>
				<option value="CURP">CURP</option>
				<option value="IFE">IFE</option>
				<option value="RFC">RFC</option>
			</select>
			<label for="doc-number">{{ languageStrings.documentId }}</label>
			<input
				type="text"
				class="curved-border encore-color bold-text padding-for-input"
				id="doc-number"
				v-model="docNumber"
			/>
		</div>
		<div class="button-container" :class ="restrictedNeedsMoreInfo&&
							fromPlayerCashCOC >=  webPayOptions.taxAmlRestrictions.buyInThresholdCOC  ? 'disable-container' : ''">
			<button class="btn buy-in-btn curved-border" :disabled="restrictedNeedsMoreInfo &&
							fromPlayerCashCOC >=  webPayOptions.taxAmlRestrictions.buyInThresholdCOC" :id="`commit-offer${buyInOffer.id}`" @click="order()">
				{{ languageStrings.commitTransaction }}
			</button>
		</div>
		<div id="directa-iframe-container" v-if="iframeSrc">
			<iframe id="directa-iframe" :src="iframeSrc"></iframe>
			<div id="btn-container">
				<router-link class="btn" title="View Transactions" to="/webPayTransactions">
					{{ languageStrings.viewTransactions }}
				</router-link>
				<router-link class="btn" :title="languageStrings.viewBalances" to="/">{{
					languageStrings.viewBalances
				}}</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import router from "@/router";

export default {
	name: "Directa",
	props: {
		playerState: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
		webPayOptions: Object,
		isMobile: Boolean,
		buyInOffer: Object,
		fromPlayerCashCOC: Number,
		restrictedNeedsMoreInfo: Boolean,
		provider: String,
	},
	data() {
		return {
			activeSession: this.session.get(),
			status: Object.assign({}, this.globalStatus),
			currencyInfo: this.playerState.currencyInfo,
			systemCurrencyInfo: this.systemCurrencyTool.currencyInfo,
			minorOrFull: this.systemCurrencyTool.displayType.minorOrFull,
			paymentType: "",
			docNumber: null,
			docType: null,
			iframeSrc: null,
		};
	},
	created() {
		window.document.addEventListener("transactionComplete", this.handlePaySuccess, false);
		window.document.addEventListener("transactionFailed", this.handlPayFailure, false);
		onBeforeUnmount(() => {
			window.document.removeEventListener("transactionComplete", this.handlePaySuccess, false);
			window.document.removeEventListener("transactionFailed", this.handlPayFailure, false);
		});
	},
	methods: {
		handlePaySuccess() {
			this.iframeSrc = "";
			this.status.ok = true;
			this.status.message = this.languageErrorStrings.success;
			this.eventBus.emit("updateStatus", this.status);
			router.push("/webPayTransactions");
		},
		handlPayFailure() {
			this.iframeSrc = "";
			this.status.ok = false;
			this.status.message = this.languageErrorStrings.somethingWentWrongTryLater;
			this.eventBus.emit("updateStatus", this.status);
		},
		async order() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.initiatingBuyIn;

			let hasErrors = false;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			if (!this.fromPlayerCashCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustEnterBuyInAmount;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (isNaN(this.fromPlayerCashCOC)) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustEnterNumberOnly;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (this.fromPlayerCashCOC < this.buyInOffer.minimumCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustMeetMinimumBuyIn;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			let maxCOC =
				this.webPayOptions?.taxAmlRestrictions?.idCheckNeeded ||
				this.webPayOptions?.taxAmlRestrictions?.morePersonalInfoNeeded
					? this.webPayOptions?.taxAmlRestrictions?.cashOutThresholdCOC
					: this.webPayOptions?.directa?.cashOutMaximumCOC;

			if (this.fromPlayerCashCOC > maxCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.cannotExceedMaximumBuyIn;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (this.provider === "directa" && this.fromPlayerCashCOC < this.webPayOptions?.directa?.buyInMinimumCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = `${this.webPayOptions?.directa?.buyInMinimumCOC} ${this.languageErrorStrings.minimumForDirectaBuyIn}`;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (this.fromPlayerCashCOC > maxCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = `${this.systemCurrencyTool.formatCurrency(
					this.webPayOptions.taxAmlRestrictions.buyInThresholdCOC,
					this.systemCurrencyTool.displayType.full
				)} ${
					this.languageErrorStrings
						.completeNeoCheckIdVerification
				}.`;
				this.eventBus.emit("updateStatus", this.status);
				router.push("/identificationStatus");
				return false;
			}

			if (hasErrors === true) return;

			// We're cheating on this.  We *need* to supply URLs for back, success, and error - they're mandatory.  However,
			// the request will be rejected if they're not HTTPS.  So if we're running locally, which is the expected case
			// We might have to lie about our address.
			const redirectUrlBase = window.location.origin.startsWith("https")
				? window.location.origin
				: window.location.origin.replace("http", "https");

			let body = {
				casinoId: this.webPayOptions.casino.id,
				buyInOfferId: this.buyInOffer.id,
				fromPlayerCashCOC: this.fromPlayerCashCOC,
				provider: "Directa",
				directa: {
					documentNumber: this.docNumber,
					documentType: this.docType,
					paymentMethodCode: this.paymentType.paymentMethodCode,
					backUrl: `${redirectUrlBase}/webPayCashOut/cashout-finished.html?ppp_status=PENDING`,
					successUrl: `${redirectUrlBase}/webPayCashOut/cashout-finished.html?ppp_status=OK`,
					errorUrl: `${redirectUrlBase}/webPayCashOut/cashout-finished.html?ppp_status=FAIL`,
				},
			};

			let requestUrl = new URL("/api/v1/webpay/buyin/initiate", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				this.transaction = await response.json();

				if (this.transaction?.status === "EmailNotVerified") {
					console.error(this.transaction?.status);
					this.status.ok = false;
					this.status.message =
						this.languageErrorStrings.emailNotVerifiedPleaseGoToYourUserProfileAndVerifyYourEmail;
					this.eventBus.emit("updateStatus", this.status);
					return;
				}

				if (this.transaction?.status === "RejectedByPaymentService") {
					console.error(this.transaction?.status);
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.rejectedByPaymentService;
					this.eventBus.emit("updateStatus", this.status);
					return;
				}

				if (fetchStatus.ok)
					this.status = sharedScripts.checkSuccessErrors(this.transaction.status, this.languageErrorStrings);

				if (this.status.ok) {
					this.iframeSrc = this.transaction.redirectUrl;
					this.status.message = this.languageErrorStrings.webPayInitiated;
					this.eventBus.emit("updateStatus", this.status);
				}
			} catch (e) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.webPayInitiateFailed;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
		async updateRfTranSatus(newStatus) {
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				webTranId: this.transaction.transaction.id,
				newStatus: newStatus,
			};

			let requestUrl = new URL("/api/v1/webpay/transaction/update", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let rfRequestStatus = await response.json();

				if (fetchStatus.ok)
					this.status = sharedScripts.checkSuccessErrors(this.transaction.status, this.languageErrorStrings);
			} catch (e) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.webPayInitiateFailed;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#payment-type-container {
	display: flex;
	flex-flow: column;
	text-align: center;
}

#payment-type-container,
#payment-type-container span {
	margin: 15px;
}

#directa-iframe-container {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgb(0 0 0 / 84%);
	backdrop-filter: blur(6px);
	z-index: 100;
	overflow: hidden auto;
}

iframe#directa-iframe {
	height: 80vh;
	width: 90vw;
}

#btn-container {
	text-align: center;
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: space-evenly;
}

.btn {
	text-decoration: none;
}

.buy-in-btn {
	width: 100%;
	height: 100%;
	margin: 0;
}
select {
	height: 35px;
}

input {
	height: 35px;
}

.padding-for-input {
	padding-left: 15px;
}

button.btn:disabled {
	background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3)) !important;
	color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3)) !important;
	border-color: light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3)) !important;
}

.disable-container {
	opacity: 30%;
}

@media (min-width: 768px) {
	#directa-iframe-container {
		width: 80%;
		left: 20%;
	}

	iframe#directa-iframe {
		width: 50vw;
	}
}
</style>
