<template>
	<div class="button-container" :class ="restrictedNeedsMoreInfo&&
							fromPlayerCashCOC  >=  webPayOptions.taxAmlRestrictions.buyInThresholdCOC  ? 'disable-container' : ''">
		<button class="btn buy-in-btn curved-border" :disabled="restrictedNeedsMoreInfo &&
							fromPlayerCashCOC   >=  webPayOptions.taxAmlRestrictions.buyInThresholdCOC"  :id="`commit-offer${buyInOffer.id}`" @click="order()">
			{{ languageStrings.commitTransaction }}
		</button>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import { onBeforeUnmount } from "vue";
import router from "@/router";

export default {
	name: "NuveiCashIn",
	props: {
		playerState: Object,
		webPayOptions: Object,
		systemCurrencyTool: Object,
		buyInOffer: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
		fromPlayerCashCOC: Number,
		provider: String, 
		restrictedNeedsMoreInfo: Boolean
	},
	components: {},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
		};
	},
	created() {
		// this.eventBus.on("initiateNuveiOrder", () => {
		// 	this.order();
		// });
		// onBeforeUnmount(() => {
		// 	console.log("onBeforeUnmount NuveiCashIn");
		// 	this.eventBus.off("initiateNuveiOrder");
		// });
	},
	mounted() {},
	methods: {
		async order() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.initiatingBuyIn;

			let hasErrors = false;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			if (!this.fromPlayerCashCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustEnterBuyInAmount;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (isNaN(this.fromPlayerCashCOC)) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustEnterNumberOnly;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (this.fromPlayerCashCOC < this.buyInOffer.minimumCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustMeetMinimumBuyIn;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (this.fromPlayerCashCOC > this.buyInOffer.maximumCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.cannotExceedMaximumBuyIn;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			let maxCOC =
				this.webPayOptions?.taxAmlRestrictions?.idCheckNeeded ||
				this.webPayOptions?.taxAmlRestrictions?.morePersonalInfoNeeded
					? this.webPayOptions?.taxAmlRestrictions?.cashOutThresholdCOC
					: this.webPayOptions?.nuvei?.cashOutMaximumCOC;

			if (this.fromPlayerCashCOC > maxCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = `${this.systemCurrencyTool.formatCurrency(
					maxCOC,
					this.systemCurrencyTool.displayType.full
				)} ${
					this.languageErrorStrings
						.completeNeoCheckIdVerification
				}.`;
				this.eventBus.emit("updateStatus", this.status);
				router.push("/identificationStatus");
				return false;
			}

			if (hasErrors === true) return;

			let body = {
				CasinoId: this.webPayOptions.casino.id,
				BuyInOfferId: this.buyInOffer.id,
				FromPlayerCashCOC: this.fromPlayerCashCOC,
				provider: this.provider,
			};


			let requestUrl = new URL("/api/v1/webpay/buyin/initiate", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				this.transaction = await response.json();

				if (this.transaction?.status === "IdCheckRequired") {
					console.error(this.transaction?.status);
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.idCheckRequired;
					this.eventBus.emit("updateStatus", this.status);
					return;
				}

				if (this.transaction?.status === "EmailNotVerified") {
					console.error(this.transaction?.status);
					this.status.ok = false;
					this.status.message =
						this.languageErrorStrings.emailNotVerifiedPleaseGoToYourUserProfileAndVerifyYourEmail;
					this.eventBus.emit("updateStatus", this.status);
					return;
				}

				if (fetchStatus.ok)
					this.status = sharedScripts.checkSuccessErrors(this.transaction.status, this.languageErrorStrings);

				if (this.status.message === "Success") {
					this.status.ok = true;
					this.status.message = this.languageErrorStrings.webPayInitiated;
					this.eventBus.emit("updateStatus", this.status);
				}

				this.performNuveiCheckout();
			} catch (e) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.webPayInitiateFailed;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
		// Dummy function, but you get the idea.  Obviously we need something more robust than this.
		getTwoLetterCountry(phoneCountryCode) {
			switch (phoneCountryCode) {
				case 1:
					return "US";
				case 52:
					return "MX";
				default:
					return "US";
			}
		},
		performNuveiCheckout() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.displayNuveiPurchaseWidget;

			/*
		Log levels for Nuvei's Simply Connect "checkout" API.
		Possible values:
			0 (default) – No logging
			1 – Plain text
			2 – Info
			3 – Warn
			4 – Error
			5 – Debug
			6 – Trace
		*/
			const nuveiLogLevel = 0;

			// About countries and payment methods:
			// The top-level country field is where we, the merchant, do business.  For Encore this will be MX, for instance.
			// The billingAddress.country is the player/user/consumer's country, and we base that on their phone number.
			// According to Andres Mauricio Clavijo Mantilla in our Teams chat on 2024-01-19, only credit cards are allowed for
			// foreigners to the business country, not "alternate payment methods" such as PayPal or OXXOPay.
			// The API fails quite severely if you try.  So we're using the "payment method whitelist" to make sure that
			// APMs aren't offered if the countries don't match.  (If no whitelist or blacklist are given, Nuvei shows all
			// methods we're set up to use.)
			const billingAddrCountry = this.getTwoLetterCountry(this.webPayOptions.userPhoneCountryCode);
			const payMethodWhitelist =
				billingAddrCountry === this.webPayOptions.nuvei.merchantTwoLetterCountryCode ? null : ["cc_card"];

			// Hard-coding the locale for this prototype.  In production, we should use whatever mechanism we're using for
			// front-end language elsewhere.
			let rfServer;
			let statusArea;
			let rfWebTransaction;
			let sessionId;
			let alreadyShowingResults;

			// if (document.getElementById("webpay-checkout-element").innerHTML !== "") {
			// 	checkout.destroy();
			// }

			// Tell Nuvei to create their UI inside one of our DOM elements.
			document.getElementById("webpay-checkout-element").innerHTML = "";
			const checkoutParams = {
				sessionToken: this.transaction.sessionId,
				env: this.webPayOptions.nuvei.environment,
				merchantSiteId: this.webPayOptions.nuvei.merchantSiteId,
				merchantId: this.webPayOptions.nuvei.merchantId,
				country: this.webPayOptions.nuvei.merchantTwoLetterCountryCode,
				currency: this.webPayOptions.cashOutCurrency.iso4217Code,
				locale: this.systemCurrencyTool.cultureCode,
				userId: this.transaction.transaction.userId,
				amount: this.transaction.transaction.fromPlayerCashCOC,
				pmWhitelist: payMethodWhitelist,
				renderTo: "#webpay-checkout-element",
				logLevel: nuveiLogLevel,
				billingAddress: {
					country: billingAddrCountry,
					email: this.webPayOptions.userEmailAddress,
					phone: this.webPayOptions.userPhoneNumber,
				},
				showResponseMessage: false,
				onResult: this.handleNuveiCheckoutResult,
				onPaymentEvent: this.handleNuveiPaymentEvent,
			};

			console.log(checkoutParams);

			checkout(checkoutParams);
			this.eventBus.emit("showNuveiWidgit");
		},
		async handleNuveiCheckoutResult(result) {
			let response = await result;

			if (response?.status === "ERROR" && !response?.statusAdditionalInfo) {
				// this.fromPlayerCashCOC = null;
				checkout.destroy();
				this.eventBus.emit("handelNuveiError", response);
				return;
			}

			if (response?.result) {
				this.nuveiResult = response;
				// this.fromPlayerCashCOC = null;
				checkout.destroy();
				setTimeout(() => {
					this.showRfTranStatus();
				}, 1500);
			}
		},
		async handleNuveiPaymentEvent(result) {
			let response = await result;

			if (response?.result) {
				// this.fromPlayerCashCOC = null;
				checkout.destroy();
				this.eventBus.emit("nuveiCheckoutResult", this.nuveiResult);
			}
		},
		async showRfTranStatus() {
			this.busyText = this.languageStrings.loadingBuyIns;
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(
				`api/v1/webpay/transaction/${this.transaction.transaction.id}`,
				this.rabbitsfootHostUrl
			);
			let params = requestUrl.searchParams;

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();
				this.transactionResult = dataJson;

				if (this.transactionResult?.status === "RefusedByProvider") {
					// this.fromPlayerCashCOC = null;
					checkout.destroy();
					this.eventBus.emit("handelNuveiError", this.transactionResult);
					return;
				}

				this.eventBus.emit("nuveiCheckoutResult", this.transactionResult);

				this.busyText = "";
				this.serverBusy = false;
			} catch (e) {
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}

			headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			requestUrl = new URL("api/v1/funds/", this.rabbitsfootHostUrl);
			params = requestUrl.searchParams;

			if (this.transaction.transaction.casinoId) params.set("casinoId", this.transaction.transaction.casinoId);

			requestUrl.search = params.toString();

			request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);
				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.playerFunds = dataJson;
			} catch (e) {
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.buy-in-btn {
	width: 100%;
	height: 100%;
	margin: 0;
}

button.btn:disabled {
	background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3)) !important;
	color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3)) !important;
	border-color: light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3)) !important;
}

.disable-container {
	opacity: 30%;
}

</style>
